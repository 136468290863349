@font-face {
  font-family: 'Noqte';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/noqte.woff2) format('woff2');
}

/*
 * Droid Arabic Naskh (Early Access) http://www.google.com/webfonts/earlyaccess
 */
@font-face {
  font-family: 'Droid Arabic Naskh';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/DroidNaskh-Regular.woff) format('woff');
}
