.post-title {
    text-align: center;
    font-size: 4em;

    @include media-query($on-palm) {
        font-size: 2em;
    }

    margin-top: 0.5em;
    line-height: 1.2;
    font-size: 3em;
}

.post-meta {
    font-size: small;
    color: $grey-color-dark;
}

.post-image {
    text-align: center;
    img {
        display: inline;
    }
}

.post-content {
    ul, ol {
        margin-left: calc($spacing-unit / 2);
    }
    iframe {
        width: 100%;
    }
    figure {
        margin: 0 auto;
        text-align: center;
        max-width: 400px;
    }
    blockquote {
        font-family: $alt-font-family;
        font-size: large;
        letter-spacing: -1px;
        >  p {
            border-right: 4px solid lighten($on-primary-color, 30%);
            padding-right: calc($spacing-unit / 2);
        }
    }
}

.pagination {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
}

.pagination--pager.disabled {
    color: lighten($primary-color, 50%);
    pointer-events: none;
    cursor: not-allowed;
}

.pagination--pager {
    padding: 1em 2em;
    width: 50%;
    font-weight: bold;
    text-align: center;
    border: 1px solid lighten($primary-color, 50%);
    border-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pagination--pager:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.post-image-float {
    max-width: 50%;
    float: left;
}

.post-content video {
    width: 100%;
}
