header {
    background-color: $primary-color;
    color: $on-primary-color;
    a {
        color: $on-primary-color;
        text-decoration: none;
    }
    a:visited {
    	color: $on-primary-color;
    }
    nav {
        display: inline;
        margin-right: 1em;
    }
}

footer {
    @extend header;
}

.content {
    margin-top: 18px;
    margin-bottom: 18px;
}

.highlighter-rouge, pre {
    max-width: 95vw;
    overflow: auto;
}

.fixed-width {
    margin-left: auto;
    margin-right: auto;
    max-width: -webkit-calc(800px - (#{$spacing-unit} * 2));
    max-width:         calc(800px - (#{$spacing-unit} * 2));
}

.card {
    height: 100%;
    background-color: $on-primary-color;
    border-style: dashed;
    border-color: lighten($primary-color, 20%);
    padding: 0 .5em;
    margin: auto 1em;
}

.list-meta li {
    display: grid;
    grid-template-columns: 1fr 3fr;
    @include media-query($on-palm) {
        display: grid;
        grid-template-columns: auto;
    }
}

.site-title {
    font-size: 26px;
    letter-spacing: -1px;
}

.meta {
    font-size: $small-font-size;
}

.list-meta-col {
    @extend .meta;

    @include media-query(400px) {
        display: none;
    }
}

#motto {
    vertical-align: calc(40%);
}

.image-caption {
    text-align: center;
    margin-top: calc($spacing-unit / 3);
}

.footnotes {
    font-size: small;
    font-family: $alt-font-family;
}

.center {
    text-align: center;
}

.button {
    border: none;
    padding: 5px 32px;
    background-color: $primary-color;
    border-radius: 2px;
    color: $on-primary-color;
    cursor: pointer;
}

@include media-query($on-palm) {
    #motto {
        display: none;
    }
}

.a-pagination {
    font-feature-settings: "liga" 1,"lnum" 1;
    text-align: center;
    * {
        line-height: 1.3;
        font-weight: 500;
        display: inline-block;
        padding: .5em .75em;
    }
    .is-active {
        font-size: x-large;
    }
}

.emoji {
    color:grey;
    filter: grayscale(100%);
}

img#footer-gravatar {
    margin-top: 1em;
    clip-path: circle();
    width: 100px;
}

.social-media-list {
    display: flex;
    justify-content: space-evenly;
    padding: 0;
    li {
        display: flex;
        flex-direction: column;
    }
}

#build-time {
    font-family: monospace;
    font-size:x-small;
    color: lighten($on-primary-color, 40%);
}

@include media-query($on-palm) {
    #footer-gravatar,
    #atom {
        display: none;
    }
    #license {
        font-size: xx-small;
        text-align: center;
    }
}

#footer-extra {
    display: flex;
    justify-content: space-between;
    @include media-query($on-palm) {
        justify-content: center;
    }
}

.dingbat {
    font-size: 5em;
    color: $primary-color;
    text-align: center;
}

.message {
    background-color: lighten($primary-color, 30%);
    color: $on-background-color;
}
