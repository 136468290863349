#static-comments {
    background-color: lighten($primary-color, 30%);
    padding: .5em;
}

#comments article {
    display: flex;
    font-size: .9rem;
    p {
        white-space: pre-wrap;
    }
}

.comment-chain {
    .sub-comment {
        margin: 5%;
        padding: 3%;
        font-size: smaller;
        p {
            margin-bottom: 0;
        }
        blockquote > p {
            padding: 10px;
        }
        @include media-query($on-palm) {
            .comment-image-wrapper {
                display: none;
            }
        }
    }
}

.comment-image-wrapper {
    box-sizing: border-box;
    max-width: 55px;
    min-width: 55px;
    margin: 0.5em;
    overflow: hidden;
}

.comment-body-wrapper {
    flex-grow: 1;
    padding: 5px;
    p {
        margin: 0;
    }
}

.comment-meta {
    display: flex;
}

.reply {
    margin-right: $spacing-unit;
}

#preview {
    font-size: small;;
    padding: 10px;
    p {
        white-space: pre-wrap;
        margin-top: 0;
    }
}

